const cardItems = document.querySelectorAll(".card");

// Check if user is visiting for the first time (you can use cookies or localStorage for this)
const isFirstVisit = true; // Replace with your actual logic

const sectionElement = document.getElementById("projects");

window.addEventListener("scroll", () => {
  const rect = sectionElement.getBoundingClientRect();
  if (rect.top + 500 <= window.innerHeight && rect.bottom >= 0) {
    if (isFirstVisit) {
      let delay = 0;
      cardItems.forEach((card, index) => {
        setTimeout(() => {
          card.classList.add("cardVisible");
          card.classList.remove("cardInVisible");
        }, delay);
        delay += 200; // Delay between each card appearance
      });
    }
  }
});
